import {
  createSlice,
  createAsyncThunk,
  AnyAction,
  AsyncThunk,
  createAction
} from '@reduxjs/toolkit'

import {getAxiosAPIInstance, getAxiosAPIInstanceWithoutToken, getAxiosWPInstance} from 'Api'
import TokenUtil from '../../Util/tokenManager'

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>

type PendingAction = ReturnType<GenericAsyncThunk['pending']>
type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>
type FulfilledAction = ReturnType<GenericAsyncThunk['fulfilled']>

interface Login {
  username: string
  password: string
}

interface Register {
  username: string
  password1: string
  password2: string
}

interface PasswordChange {
  old_password: string
  new_password1: string
  new_password2: string
}

interface VerifyEmail {
  key: string
}

interface EmailChange {
  password: string
  new_username: string
}

interface ContactForm {
  content: string
}

interface TOSContent {
  tos_content: any
  user_survey_link: {
    url: string
    link_text: { [language: string]: string }
  } | null
  error: any
}

interface AuthState {
  entities: TOSContent
  navigation: any
  loading: 'idle' | 'pending' | 'fulfilled' | 'rejected'
  authed: boolean
  loginError: unknown
  registrationError: boolean
  active_subscription: boolean
  username: string | null
  expiration_date: string | null
}

const initialState: AuthState = {
  entities: {
    tos_content: {
      fi: '',
      sv: '',
      en: ''
    },
    user_survey_link: null,
    error: null
  },
  navigation: [],
  loading: 'idle',
  authed: false,
  loginError: null,
  registrationError: false,
  active_subscription: false,
  username: null,
  expiration_date: null
}

type HeaderNavigationRes = {
  ID: number
  attr_title: string
  classes: string[]
  comment_count: string
  comment_status: string
  db_id: number
  description: string
  filter: string
  guid: string
  menu_item_parent: string
  menu_order: number
  object: string
  object_id: string
  ping_status: string
  pinged: string
  post_author: string
  post_content: string
  post_content_filtered: string
  post_date: string
  post_date_gmt: string
  post_excerpt: string
  post_mime_type: string
  post_modified: string
  post_modified_gmt: string
  post_name: string
  post_parent: number
  post_password: string
  post_status: string
  post_title: string
  post_type: string
  target: string
  title: string
  to_ping: string
  type: string
  type_label: string
  url: string
  xfn: string
}

const fetchCurrentUser = createAsyncThunk(
  'auth/fetch',
  // if you type your function argument here
  async () => {
    const response = await getAxiosAPIInstance().get(`/auth/user/`)
    return response.data
  }
)

const fetchHeaderNavigationElements = createAsyncThunk<
  HeaderNavigationRes[],
  string | null
>('api/header', async (lang, { rejectWithValue }) => {
  try {
    const response = await getAxiosWPInstance().get(`/mainmenu-${lang}/`)
    return response.data
  } catch (error) {
    return handleError(error, rejectWithValue)
  }
})

const fetchInitialSettings = createAsyncThunk(
  'api/settings',
  async (data, { rejectWithValue }) => {
    try {
      const response = await getAxiosAPIInstance().get(`/api/settings/`)
      return response.data
    } catch (error) {
      return handleError(error, rejectWithValue)
    }
  }
)

const loginUser = createAsyncThunk(
  'auth/login',
  // if you type your function argument here
  async ({ username, password }: Login) => {
    const response = await getAxiosAPIInstance().post(`/auth/login/`, {
      username,
      password
    })
    return response.data
  }
)

const registerUser = createAsyncThunk(
  'auth/registration',
  // if you type your function argument here
  async ({ username, password1, password2 }: Register) => {
    const response = await getAxiosAPIInstance().post(`/auth/registration/`, {
      username,
      email: username,
      password1,
      password2
    })
    return response.data
  }
)

const changePasswordUser = createAsyncThunk(
  'auth/password/change',
  // if you type your function argument here
  async ({ old_password, new_password1, new_password2 }: PasswordChange) => {
    const response = await getAxiosAPIInstance().post(`/auth/password/change/`, {
      old_password,
      new_password1,
      new_password2
    })
    return response.data
  }
)

const verifyEmail = createAsyncThunk(
  'auth/registration/verify-email',
  // if you type your function argument here
  async ({ key }: VerifyEmail) => {
    const response = await getAxiosAPIInstanceWithoutToken().post(`/auth/registration/verify-email/`, {
      key
    })
    return response.data
  }
)

const changeEmailUser = createAsyncThunk(
  'auth/password/change',
  // if you type your function argument here
  async ({ password, new_username, }: EmailChange) => {
    const response = await getAxiosAPIInstance().post(`/auth/email/change/`, {
      password,
      new_username
    })
    return response.data
  }
)

const sendContact = createAsyncThunk(
  'api/contact',
  // if you type your function argument here
  async ({ content, }: ContactForm) => {
    const response = await getAxiosAPIInstance().post(`/api/contact/`, {
      content,
    })
    return response.data
  }
)

const logoutUser = createAsyncThunk(
  'auth/logout',
  // if you type your function argument here
  async () => {
    const response = await getAxiosAPIInstance().post(`/auth/logout/`)
    return response.data
  }
)

const REDUCER_NAME = 'auth'

const isPendingAction = (action: AnyAction): action is PendingAction => {
  return (
    action.type.startsWith(REDUCER_NAME) && action.type.endsWith('/pending')
  )
}

const isRejectedAction = (action: AnyAction): action is RejectedAction => {
  return (
    action.type.startsWith(REDUCER_NAME) && action.type.endsWith('/rejected')
  )
}
const isFulfilledAction = (action: AnyAction): action is FulfilledAction => {
  return (
    action.type.startsWith(REDUCER_NAME) && action.type.endsWith('/fulfilled')
  )
}

export const resetAction = createAction('reset-tracked-loading-state')

export const handleError = (error: any, rejectWithValue: any) => {
  if (!error.response) {
    throw error
  }

  const response = error.response
  const data = response.data
  let errorMessage = data

  if (data instanceof Object) {
    errorMessage = 'error' in data ? data.error : JSON.stringify(data.error)
  }

  return rejectWithValue({
    status: response.status,
    message: errorMessage
  })
}

const authSlice = createSlice({
  name: REDUCER_NAME,
  initialState,
  reducers: {
    // fill in primary logic here
  },
  extraReducers: builder => {
    builder
      .addCase(resetAction, () => initialState)
      .addCase(fetchInitialSettings.fulfilled, (state, { payload }) => {
        state.entities = payload
        state.entities.error = null
      })
      .addCase(fetchInitialSettings.rejected, (state, { payload }) => {
        state.entities.error = payload
      })
      .addCase(
        fetchHeaderNavigationElements.fulfilled,
        (state, { payload }) => {
          /* Loop through navigation data and push to store */
          const navigation = payload
            .filter(item => item.menu_item_parent === '0')
            .map(parent => {
              const children = payload
                .filter(item => item.menu_item_parent === parent.post_name)
                .map(item => ({
                  title: item.title,
                  url: item.url
                }))
              return {
                title: parent.title,
                url: parent.url,
                children
              }
            })

          state.navigation = navigation
        }
      )
      .addCase(registerUser.fulfilled, (state, { payload }) => {
        TokenUtil.set(payload.key)
        state.active_subscription = false
        state.registrationError = false
        state.authed = true
      })
      .addCase(registerUser.rejected, (state, action) => {
        TokenUtil.remove()
        state.active_subscription = false
        state.registrationError = true
        state.authed = false
      })
      .addCase(fetchCurrentUser.fulfilled, (state, { payload }) => {
        state.active_subscription = payload.active_subscription
        state.username = payload.username
        state.expiration_date = payload.expiration_date
        state.authed = true
      })
      .addCase(fetchCurrentUser.rejected, state => {
        TokenUtil.remove()
        state.active_subscription = false
        state.authed = false
      })
      .addCase(loginUser.fulfilled, (state, { payload }) => {
        TokenUtil.set(payload.key)
        state.active_subscription = payload.active_subscription
        state.loginError = null
        state.authed = true
      })
      .addCase(loginUser.rejected, (state, { error }) => {
        TokenUtil.remove()
        state.active_subscription = false
        state.loginError = error
        state.authed = false
      })
      .addCase(logoutUser.pending, state => {
        TokenUtil.remove()

        // Remove Auth header from Axios
        setTimeout(
          () =>
            delete getAxiosAPIInstance().defaults.headers.common[
              'Authorization'
            ],
          100
        )
        state.active_subscription = false
        state.authed = false
      })
      .addMatcher(isPendingAction, state => {
        state.loading = 'pending'
      })
      .addMatcher(isRejectedAction, state => {
        state.loading = 'rejected'
      })
      .addMatcher(isFulfilledAction, state => {
        state.loading = 'fulfilled'
      })
  }
})

export default authSlice.reducer
export {
  fetchCurrentUser,
  loginUser,
  registerUser,
  changePasswordUser,
  changeEmailUser,
  sendContact,
  logoutUser,
  fetchInitialSettings,
  fetchHeaderNavigationElements,
  verifyEmail,
}

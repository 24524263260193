import { useEffect } from 'react'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'

import { FrontPage, LoginPageScreen, SingleSearchResult } from 'Pages'

import './App.scss'
import { useAppDispatch } from '../Store'
import TokenUtil from '../Util/tokenManager'
import {
  fetchCurrentUser,
  fetchInitialSettings,
  fetchHeaderNavigationElements
} from '../Features/Auth/authSlice'
import FirstStep from '../Pages/Payment/FirstStep'
import SecondStep from '../Pages/Payment/SecondStep'
import PrivateRoute from '../Util/PrivateRoute'
import Success from '../Pages/Payment/Success'
import LogoutPage from '../Pages/LoginPage/LogoutPage'
import i18next from 'i18next'
import TopBlockSmall from '../Components/SubscribeService/TopBlockSmall'
import PersonalInfoScreen from "../Pages/PersonalInfo/PersonalInfo";
import PasswordChangeScreen from "../Pages/PersonalInfo/PasswordChange";
import EmailChangeScreen from "../Pages/PersonalInfo/EmailChange";
import AuthHeaderBlock from "../Components/SubscribeService/AuthHeader";
import MuiXLicense from "./MUILicence";
import EmailVerification from "../Pages/PersonalInfo/EmailVerification";

const App = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    const key = TokenUtil.get()
    if (key) {
      dispatch(fetchCurrentUser())
    }

    // Initial data fetch (settings & header data)
    dispatch(fetchInitialSettings())

    const params = new URLSearchParams(window.location.search)
    let lang = params.get('lang') || 'fi'

    if (!Object.keys(i18next.services.resourceStore.data).includes(lang)) {
      lang = 'fi'
    }

    dispatch(fetchHeaderNavigationElements(lang))
    i18next.changeLanguage(lang)
  })

  return (
    <Router>
      <div className="App">
        <MuiXLicense />
        <AuthHeaderBlock />
        <TopBlockSmall />
        <Switch>
          <Route component={EmailVerification} path="/verify-email/:key/" />
          <Route component={PersonalInfoScreen} path="/personal-info" />
          <Route component={PasswordChangeScreen} path="/password-change" />
          <Route component={EmailChangeScreen} path="/email-change" />
          <Route component={LoginPageScreen} path="/migration-login" />
          <Route component={LogoutPage} path="/migration-logout" />
          <Route component={SingleSearchResult} path="/:registry/:id" />
          <PrivateRoute component={FirstStep} path="/expired" />
          <PrivateRoute component={SecondStep} path="/payment" />
          <PrivateRoute component={Success} path="/success" />
          <Route component={FrontPage} path="/" />
        </Switch>
      </div>
    </Router>
  )
}

export default App

import { useEffect, useState } from 'react'
import {useAppDispatch} from '../../Store'
import {
  Background,
  WhiteButtonLink, BackgroundAlignCenter
} from "./Components"
import {useTranslation} from "react-i18next"
import {verifyEmail} from "../../Features/Auth/authSlice";
import {useParams} from "react-router-dom";

interface PageParams {
  key: string
}

const EmailVerification = () => {
  const { key } = useParams<PageParams>()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const [verifyHappened, setVerifyHappened] = useState(false)
  const [errorHappened, setErrorHappened] = useState(false)
  const [successHappened, setSuccessHappened] = useState(false)
  
  useEffect(() => {
    if (!verifyHappened) {
      dispatch(verifyEmail({
        key
      })).then((response) => {
        if ("error" in response) {
          setErrorHappened(true)
        } else {
          setSuccessHappened(true)
        }
      })
      setVerifyHappened(true)
    }
  }, [verifyHappened, setVerifyHappened, key, dispatch, setErrorHappened, setSuccessHappened])

  return (
    <Background>
      <BackgroundAlignCenter>
        <h1>
          { successHappened ? t('Email activation successful') : null}
          { errorHappened ? t('Email activation failed') : null}
        </h1>
        <p>
          { successHappened ? t('Email activation successful description') : null}
          { errorHappened ? t('Email activation failed descrption') : null}
        </p>
        <br />
        <WhiteButtonLink to={`/migration-login`}>{t('Move to login page')}</WhiteButtonLink>
      </BackgroundAlignCenter>
    </Background>
  )
}

export default EmailVerification

import styled from "styled-components"
import React from "react"
import {useTranslation} from "react-i18next"
import moment from "moment"

const Container = styled.div`
  margin: 32px 0;
`

interface OrderStatusBoxInterface {
  isActive?: any
  expirationInfo?: any
}

const IsActiveBox = styled.div<{isActive?: any}>`
  color: ${props => (props.isActive ? 'green' : 'red')};
  
  span {
    padding-right: 8px;
  }
`

const OrderStatusBox: React.FC<OrderStatusBoxInterface> = ({ isActive, expirationInfo }) => {
  const { t } = useTranslation()
  
  let date = null
  if (expirationInfo) {
    date = moment(expirationInfo).utc().format("DD.MM.YYYY")
  }
  
  return (
    <Container>
      <strong>{t(`Subscription`)}</strong>
      <IsActiveBox isActive={isActive}>
        <span>
          {
            isActive ? 
            <i className="fa fa-check" aria-hidden="true"></i> : 
            <i className="fa fa-remove" aria-hidden="true"></i>
          }
        </span>
        <span>{isActive ? t(`Active`) : t(`Ended`)}</span>
      </IsActiveBox>
      {expirationInfo ? <div>{t(`Expires on`)}: { date ? date : t(`Not available.`)}</div> : null}
    </Container>
  )
}

export default OrderStatusBox